import { Shield, Award, TrendingUp } from 'lucide-react';
import PlanCard from '../shared/PlanCard';

const livingBenefits = [
  {
    name: 'Accelerated Death Benefits',
    description: 'Access funds when diagnosed with qualifying terminal illness',
    icon: Award,
    features: [
      'Early access to death benefit',
      'No restrictions on fund usage',
      'Typically included at no extra cost',
      'Provides financial relief during illness'
    ],
    benefits: [
      'Medical expense coverage',
      'Quality of life improvements',
      'Reduced financial burden',
      'Peace of mind during difficult times'
    ]
  },
  {
    name: 'Cash Value Access',
    description: 'Use accumulated cash value during your lifetime',
    icon: Shield,
    features: [
      'Policy loans available',
      'Potential tax advantages',
      'Withdraw funds when needed',
      'Supplement retirement income'
    ],
    benefits: [
      'Financial flexibility',
      'Emergency fund access',
      'Wealth building potential',
      'Living benefit while maintaining coverage'
    ]
  },
  {
    name: 'Critical Illness Riders',
    description: 'Financial protection if diagnosed with serious illness',
    icon: TrendingUp,
    features: [
      'Lump-sum payments',
      'Coverage for specific illnesses',
      'Added to base policy',
      'Use funds at your discretion'
    ],
    benefits: [
      'Medical expense coverage',
      'Replace lost income',
      'Maintain financial stability',
      'Focus on recovery, not finances'
    ]
  }
];

export default function LivingBenefits() {
  return (
    <div className="space-y-16">
      {/* Introduction */}
      <div className="rounded-2xl bg-gradient-to-br from-primary-50 to-white p-8 border border-primary-100 shadow-sm">
        <h2 className="text-2xl font-bold text-gray-900">
          Living Benefits & Features
        </h2>
        <p className="mt-2 text-gray-600">
          Beyond death benefits, our life insurance solutions offer living benefits that provide 
          value while the policyholder is still alive. These features provide flexibility and
          financial protection for various life events.
        </p>
      </div>

      {/* Benefits Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {livingBenefits.map((benefit) => (
          <PlanCard key={benefit.name} plan={benefit} />
        ))}
      </div>

      {/* Additional Information */}
      <div className="rounded-2xl bg-white p-8 shadow-md border border-gray-100">
        <h3 className="text-lg font-semibold text-gray-900">
          Additional Living Benefits
        </h3>
        <ul className="mt-4 space-y-3">
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
            Critical Illness Riders: Access to benefits if diagnosed with a qualifying illness
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
            Chronic Illness Benefits: Financial support for long-term care needs
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
            Premium Waiver Options: Coverage continues even if unable to pay premiums due to disability
          </li>
          <li className="flex items-center text-sm text-gray-600">
            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
            Terminal Illness Advance: Early access to death benefits for terminal conditions
          </li>
        </ul>
      </div>
    </div>
  );
}