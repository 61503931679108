import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Star, Award, Shield, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';
import { Card, CardHeader, CardFooter } from '../ui/Card';

// Expanded carrier data with additional information
const carriers = [
  {
    name: 'Humana',
    logo: '/assets/images/carriers/humana/humana-logo.svg',
    fallbackText: 'Humana',
    category: 'Medicare',
    rating: 4.5,
    specialties: ['Medicare Advantage', 'Prescription Drug Plans'],
    description: 'Leading Medicare insurance solutions with competitive rates and comprehensive coverage options.'
  },
  {
    name: 'UnitedHealthcare',
    logo: '/assets/images/carriers/uhc/uhc-logo.svg',
    fallbackText: 'UHC',
    category: 'Health',
    rating: 4.7,
    specialties: ['Group Health', 'Medicare Supplements'],
    description: 'Comprehensive health insurance solutions for individuals and businesses.'
  },
  {
    name: 'American Amicable',
    logo: '/assets/images/carriers/amam/amam-logo.svg',
    fallbackText: 'American Amicable',
    category: 'Life',
    rating: 4.2,
    specialties: ['Term Life', 'Whole Life'],
    description: 'Innovative life insurance products designed for families and businesses.'
  },
  {
    name: 'Cigna',
    logo: '/assets/images/carriers/cigna/cigna-logo.svg',
    fallbackText: 'Cigna',
    category: 'Health',
    rating: 4.4,
    specialties: ['Individual Health', 'Dental & Vision'],
    description: 'Trusted health insurance solutions with a focus on preventive care and wellness.'
  },
  {
    name: 'Devoted Health',
    logo: '/assets/images/carriers/devoted/devoted-logo.svg',
    fallbackText: 'Devoted Health',
    category: 'Medicare',
    rating: 4.3,
    specialties: ['Medicare Advantage', 'Special Needs Plans'],
    description: 'Technology-driven Medicare solutions with excellent customer service.'
  },
  {
    name: 'Foresters Financial',
    logo: '/assets/images/carriers/forresters/forresters-logo.svg',
    fallbackText: 'Foresters',
    category: 'Life',
    rating: 4.1,
    specialties: ['Term Life', 'Final Expense'],
    description: 'Member-owned life insurance products with unique community benefits.'
  },
];

// Available carrier categories
const categories = ['All', 'Medicare', 'Health', 'Life'];

export default function Carriers() {
  const [activeCategory, setActiveCategory] = useState('All');
  const [activeCarrier, setActiveCarrier] = useState<string | null>(null);
  
  // Filter carriers by category
  const filteredCarriers = activeCategory === 'All' 
    ? carriers 
    : carriers.filter(carrier => carrier.category === activeCategory);

  return (
    <section id="carriers" className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5 mb-4">
            <span className="text-sm font-medium text-primary-700">Elite Partnerships</span>
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Trusted Carriers
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Partner with industry-leading insurance carriers offering competitive 
            products and reliable coverage for your clients.
          </p>
        </div>
        
        {/* Category tabs */}
        <div className="mt-12 flex justify-center">
          <div className="inline-flex rounded-md shadow-sm bg-white border border-gray-200 p-1">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setActiveCategory(category)}
                className={`px-4 py-2 text-sm font-medium rounded ${
                  activeCategory === category
                    ? 'bg-primary-50 text-primary-700 border-primary-100 shadow-sm'
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                } transition-all`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        
        {/* Carrier grid with enhanced styling */}
        <div className="mx-auto mt-10 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredCarriers.map((carrier) => (
            <Card 
              key={carrier.name}
              variant="default"
              size="md"
              isHoverable
              className={`transition-all duration-300 ${
                activeCarrier === carrier.name ? 'ring-2 ring-primary-400 ring-offset-2' : ''
              }`}
              onClick={() => setActiveCarrier(carrier.name !== activeCarrier ? carrier.name : null)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="w-12 h-12 flex items-center justify-center bg-primary-50 rounded-md border border-primary-100">
                    <span className="font-bold text-primary-700">{carrier.fallbackText.substring(0, 2)}</span>
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">{carrier.name}</h3>
                    <div className="flex items-center mt-1">
                      <div className="flex items-center text-yellow-400">
                        <Star className="w-4 h-4 fill-current" />
                        <span className="ml-1 text-sm text-gray-600">{carrier.rating}</span>
                      </div>
                      <span className="mx-2 text-gray-300">•</span>
                      <span className="text-sm text-gray-500">{carrier.category}</span>
                    </div>
                  </div>
                </div>
                
                <div className="rounded-full bg-primary-50 p-1">
                  <Shield className="w-4 h-4 text-primary-600" />
                </div>
              </div>
              
              {activeCarrier === carrier.name && (
                <div className="mt-4 pt-4 border-t border-gray-100 animate-fadeIn">
                  <p className="text-sm text-gray-600 mb-3">{carrier.description}</p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {carrier.specialties.map((specialty) => (
                      <span 
                        key={specialty} 
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-50 text-primary-700"
                      >
                        {specialty}
                      </span>
                    ))}
                  </div>
                  <Link 
                    to={`/carriers/${carrier.name.toLowerCase().replace(/\s+/g, '-')}`}
                    className="inline-flex items-center text-sm text-primary-600 hover:text-primary-500"
                  >
                    View products
                    <ArrowRight className="ml-1 w-3 h-3" />
                  </Link>
                </div>
              )}
            </Card>
          ))}
        </div>
        
        <div className="mt-16 text-center">
          <Link to="/carriers/directory">
            <Button
              variant="outline"
              size="lg"
              className="shadow-sm border-primary-500 text-primary-600 hover:bg-primary-50"
            >
              View All Carriers
            </Button>
          </Link>
          <p className="mt-4 text-sm text-gray-500">
            Access contracts with 50+ top-rated insurance carriers
          </p>
        </div>
      </div>
    </section>
  );
}