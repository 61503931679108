import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import { MAIN_NAV } from '../../constants/navigation';
import { cn } from '../../lib/utils';
import { useDisableScroll } from '../../hooks/useDisableScroll';
import { useClickAway } from '../../hooks/useClickAway';
import Logo from '../ui/Logo';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Handle click outside
  useClickAway(menuRef, onClose);

  const toggleSection = (name: string) => {
    setActiveSection(current => current === name ? null : name);
  };

  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 flex items-start lg:hidden',
        'transition-all duration-300',
        isOpen ? 'bg-black/50' : 'pointer-events-none bg-transparent'
      )}
    >
      {/* Menu Panel */}
      <div
        ref={menuRef}
        className={cn(
          'h-full w-[85%] max-w-md bg-white',
          'transform transition-all duration-300 ease-out',
          'flex flex-col',
          'shadow-2xl border-r border-gray-200/50',
          isOpen ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        {/* Header */}
        <div className="flex h-24 sm:h-32 items-center justify-between border-b border-gray-200 px-6 sm:px-8">
          <Logo size="lg" />
        </div>

        {/* Navigation */}
        <nav className="flex-1 overflow-y-auto overscroll-contain px-2 py-4" aria-label="Mobile menu">
          <ul className="space-y-2">
            {MAIN_NAV.map((item) => (
              <li key={item.name}>
                {item.items ? (
                  <div>
                    <button
                      onClick={() => toggleSection(item.name)}
                      className={cn(
                        'flex w-full items-center justify-between rounded-lg p-3',
                        'text-base font-medium',
                        'transition-all duration-200',
                        'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2',
                        'border-l-2',
                        activeSection === item.name
                          ? 'bg-primary-50/80 text-primary-800 shadow-sm border-l-primary-500'
                          : 'text-gray-900 hover:bg-gray-50/80 border-l-transparent hover:border-l-primary-400'
                      )}
                      aria-expanded={activeSection === item.name}
                      aria-controls={`mobile-${item.name.toLowerCase().replace(/\s+/g, '-')}-dropdown`}
                    >
                      <div className="flex items-center space-x-3" id={`mobile-${item.name.toLowerCase().replace(/\s+/g, '-')}-button`}>
                        {item.icon && (
                          <item.icon
                            className={cn(
                              'h-5 w-5 transition-colors',
                              activeSection === item.name
                                ? 'text-primary-800'
                                : 'text-gray-500'
                            )}
                          />
                        )}
                        <span>{item.name}</span>
                      </div>
                      <ChevronDown 
                        className={cn(
                          'h-5 w-5 transition-transform duration-200',
                          activeSection === item.name 
                            ? 'rotate-180 text-primary-800' 
                            : 'text-gray-400'
                        )}
                        aria-hidden="true"
                      />
                    </button>
                    
                    <div
                      id={`mobile-${item.name.toLowerCase().replace(/\s+/g, '-')}-dropdown`}
                      className={cn(
                        'mt-1 overflow-hidden rounded-lg',
                        'transition-all duration-200 ease-in-out',
                        activeSection === item.name ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'
                      )}
                      role="menu"
                      aria-labelledby={`mobile-${item.name.toLowerCase().replace(/\s+/g, '-')}-button`}
                    >
                      {item.items.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.href}
                          onClick={onClose}
                          className={cn(
                            'block rounded-lg px-8 py-3',
                            'transition-all duration-200',
                            'border-l-2 border-l-transparent',
                            'hover:bg-primary-50/50 hover:shadow-sm hover:border-l-primary-400 active:bg-primary-100/50',
                            'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-1'
                          )}
                        >
                          <div className="text-sm text-gray-700 transition-colors hover:text-primary-900">
                            <div className="font-medium">{subItem.name}</div>
                            {subItem.description && (
                              <p className="mt-0.5 text-xs text-gray-500">
                                {subItem.description}
                              </p>
                            )}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    onClick={onClose}
                    className={cn(
                      'flex items-center space-x-3 rounded-lg p-3',
                      'text-base font-medium text-gray-900',
                      'transition-all duration-200',
                      'border-l-2 border-l-transparent',
                      'hover:bg-primary-50/80 hover:text-primary-700 hover:shadow-sm hover:border-l-primary-400 active:bg-primary-100/80',
                      'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2'
                    )}
                  >
                    {item.icon && (
                      <item.icon className="h-5 w-5 text-gray-500" />
                    )}
                    <span>{item.name}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Footer */}
        <div className="border-t border-gray-100/50 p-4 sm:p-5 backdrop-blur-xl">
          <Link
            to="/contact"
            onClick={onClose}
            className={cn(
              'flex items-center justify-center rounded-lg bg-primary-600 px-4 py-3 sm:py-3.5',
              'text-base font-semibold text-white',
              'transition-all duration-200',
              'hover:bg-primary-500 hover:shadow-md active:bg-primary-700',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2',
              'border border-primary-500/20'
            )}
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>,
    document.body
  );
}

export default function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  
  // Lock scroll when menu is open
  useDisableScroll(isOpen);

  // Close on escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setIsOpen(false);
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
      return () => window.removeEventListener('keydown', handleEscape);
    }
  }, [isOpen]);

  return (
    <div className="lg:hidden" role="navigation" aria-label="Mobile navigation">
      <div className="fixed top-0 left-0 right-0 z-50 flex h-24 sm:h-28 items-center justify-between bg-white px-6 sm:px-8 shadow-sm border-b border-gray-200">
        <Logo size="lg" />
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={cn(
            'flex h-10 w-10 sm:h-12 sm:w-12 items-center justify-center rounded-lg',
            'transition-all duration-200',
            'hover:bg-primary-50/80 active:bg-primary-100/80',
            'focus:outline-none focus-visible:ring-2',
            'focus-visible:ring-primary-500 focus-visible:ring-offset-2',
            'border border-gray-200/50',
            isOpen && 'bg-primary-50/80 border-primary-200/50'
          )}
        >
          <span className="sr-only">
            {isOpen ? 'Close menu' : 'Open menu'}
          </span>
          {isOpen ? (
            <X className="h-5 w-5 sm:h-6 sm:w-6 text-primary-700" />
          ) : (
            <Menu className="h-5 w-5 sm:h-6 sm:w-6 text-primary-700" />
          )}
        </button>
      </div>

      <MobileMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}
