import { useState } from 'react';
import { Shield, Heart, Stethoscope } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';

const marketplacePlans = [
  {
    name: 'Marketplace Individual Plans',
    description: 'ACA-compliant with potential subsidies',
    icon: Shield,
    features: [
      'Premium tax credits available',
      'Cost-sharing reductions',
      'Essential health benefits',
      'Guaranteed issue'
    ],
    benefits: [
      'Income-based affordability',
      'Preventive care at no cost',
      'No pre-existing condition exclusions',
      'Standardized benefit tiers'
    ]
  },
  {
    name: 'Marketplace Family Coverage',
    description: 'Subsidized protection for families',
    icon: Heart,
    features: [
      'Family premium subsidies',
      'Family out-of-pocket maximums',
      'Pediatric dental & vision',
      'Maternity coverage'
    ],
    benefits: [
      'Income-based premium assistance',
      'Children covered to age 26',
      'Comprehensive family coverage',
      'Special enrollment periods'
    ]
  }
];

const offMarketplacePlans = [
  {
    name: 'Short-Term Health Plans',
    description: 'Temporary coverage solutions',
    icon: Shield,
    features: [
      'Flexible coverage periods',
      'Quick application process',
      'Lower premiums than ACA plans',
      'Coverage for unexpected illness/injury'
    ],
    benefits: [
      'Bridging coverage gaps',
      'No open enrollment restrictions',
      'Customizable coverage options',
      'Rapid approval process'
    ]
  },
  {
    name: 'Health Share Programs',
    description: 'Alternative to traditional insurance',
    icon: Heart,
    features: [
      'Faith-based or community options',
      'Monthly sharing contributions',
      'Medical expense sharing',
      'Flexible program structure'
    ],
    benefits: [
      'Often lower monthly costs',
      'Community-focused approach',
      'Exemption from ACA mandate',
      'Value-aligned healthcare'
    ]
  },
  {
    name: 'Self-Funded Options',
    description: 'Employer-sponsored alternatives',
    icon: Stethoscope,
    features: [
      'Level-funded plans',
      'Reference-based pricing',
      'Customized plan design',
      'Direct primary care integration'
    ],
    benefits: [
      'Potential cost savings',
      'Customized benefits',
      'Data transparency',
      'Regulatory flexibility'
    ]
  }
];

// Enrollment information content
const enrollmentPeriods = [
  {
    title: "Open Enrollment Period",
    dates: "November 1 - January 15 (varies by state)",
    description: "Annual period when anyone can enroll in ACA-compliant health insurance plans."
  },
  {
    title: "Special Enrollment Period",
    dates: "Varies based on qualifying event",
    description: "Opportunity to enroll outside of Open Enrollment due to life changes such as marriage, birth of a child, or loss of other coverage."
  },
  {
    title: "Employer Open Enrollment",
    dates: "Varies by employer",
    description: "Period when employees can enroll in or make changes to their employer-sponsored health insurance."
  },
  {
    title: "Off-Marketplace Enrollment",
    dates: "Year-round for certain plans",
    description: "Short-term health plans and health share programs often allow enrollment throughout the year."
  }
];

export default function HealthInsurancePage() {
  const [activeTab, setActiveTab] = useState('plans');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Enhanced Page Header with Large Icon */}
        <div className="relative py-16 lg:py-24">
          {/* Background with subtle gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-70"></div>
          
          <div className="relative">
            <div className="mx-auto max-w-5xl">
              <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                {/* Left side with large icon */}
                <div className="lg:w-1/3 flex justify-center">
                  <div className="bg-white p-6 rounded-xl shadow-lg border border-primary-100">
                    <img 
                      src="/assets/images/insurance/health/health-icon.png" 
                      alt="Health Insurance"
                      className="h-32 w-32 lg:h-40 lg:w-40"
                    />
                  </div>
                </div>
                
                {/* Right side with text content */}
                <div className="lg:w-2/3 text-left">
                  <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5 mb-4">
                    <span className="text-sm font-medium text-primary-700">Healthcare Solutions</span>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Health Insurance Solutions
                  </h1>
                  <p className="mt-6 text-lg text-gray-600">
                    United Insurance Professionals provides comprehensive health insurance solutions
                    designed to offer quality healthcare coverage at competitive rates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Health Insurance Navigation">
          <button
            onClick={() => setActiveTab('plans')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'plans' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Plan Options
          </button>
          <button
            onClick={() => setActiveTab('comparison')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'comparison' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Plan Comparison
          </button>
          <button
            onClick={() => setActiveTab('enrollment')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'enrollment' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Enrollment Info
          </button>
        </nav>

        {/* Plans Tab */}
        {activeTab === 'plans' && (
          <div className="mb-16">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Health Insurance Plan Options</h2>
              <p className="text-gray-600 mb-8">
                United Insurance Professionals offers a variety of health insurance solutions through both marketplace and off-marketplace plans.
              </p>
            </div>
            
            <div className="mb-12">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Marketplace Plans</h3>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
                {marketplacePlans.map((plan) => (
                  <PlanCard key={plan.name} plan={plan} />
                ))}
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Off-Marketplace Plans</h3>
              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                {offMarketplacePlans.map((plan) => (
                  <PlanCard key={plan.name} plan={plan} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Comparison Tab */}
        {activeTab === 'comparison' && (
          <div className="mb-16">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Health Insurance Comparison</h2>
              <p className="text-gray-600 mb-8">
                Compare key features of marketplace and off-marketplace health insurance plans to find the best fit for your clients.
              </p>
            </div>
            
            {/* Health Insurance Diagram */}
            <div className="mb-8 bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="flex flex-col md:flex-row items-center gap-6">
                  <div className="md:w-1/3 flex justify-center">
                    <img 
                      src="/assets/images/insurance/health/health-diagram.png" 
                      alt="Health insurance plan comparison"
                      className="w-full max-w-[240px] h-auto"
                    />
                  </div>
                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold text-gray-900 mb-3">Understanding Health Plan Options</h3>
                    <p className="text-gray-600 mb-4">
                      This diagram illustrates the structure of health insurance plan options, 
                      helping you guide clients to choose between marketplace and off-marketplace plans.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="bg-blue-50 p-3 rounded-lg">
                        <h4 className="font-medium text-blue-800">Marketplace Plans</h4>
                        <p className="text-sm text-gray-600">ACA-compliant with potential premium tax credits</p>
                      </div>
                      <div className="bg-blue-50 p-3 rounded-lg">
                        <h4 className="font-medium text-blue-800">Off-Marketplace Plans</h4>
                        <p className="text-sm text-gray-600">Alternative options with more flexible enrollment</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="overflow-hidden rounded-xl bg-white shadow ring-1 ring-gray-200">
              <div className="p-6 sm:p-8">
                <h3 className="text-xl font-semibold text-gray-900">
                  Plan Comparison: Marketplace vs. Off-Marketplace
                </h3>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                        Features
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                        Marketplace Plans
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                        Off-Marketplace Plans
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Premium Subsidies
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Available
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Not Available
                      </td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Essential Health Benefits
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Required
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Varies by Plan
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Enrollment Period
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Limited to Open Enrollment
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Year-Round Enrollment
                      </td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Pre-existing Conditions
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Covered
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        May Be Limited
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Monthly Cost
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Higher (with subsidies: varies)
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Generally Lower
                      </td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Network Restrictions
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Often Strict
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        Varies by Plan Type
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                        Maximum Coverage Limits
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        No Annual/Lifetime Limits
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                        May Have Limits
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        
        {/* Enrollment Tab */}
        {activeTab === 'enrollment' && (
          <div className="mb-16">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Health Insurance Enrollment Information</h2>
              <p className="text-gray-600 mb-8">
                Understanding enrollment periods is essential for securing health insurance coverage. Below are the key enrollment windows for different types of health insurance plans.
              </p>
            </div>
            
            <div className="space-y-8">
              {enrollmentPeriods.map((period, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="text-lg font-semibold text-gray-900">{period.title}</h3>
                  <p className="mt-2 text-sm font-medium text-blue-600">{period.dates}</p>
                  <p className="mt-2 text-gray-600">{period.description}</p>
                </div>
              ))}
            </div>
            
            <div className="mt-10 bg-blue-50 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900">Important Considerations</h3>
              <ul className="mt-4 space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600"></span>
                  <span><strong>Missing Open Enrollment:</strong> If clients miss the Open Enrollment Period, they may have to wait until the next year unless they qualify for a Special Enrollment Period.</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600"></span>
                  <span><strong>Documentation:</strong> For Special Enrollment Periods, clients will need to provide documentation of their qualifying life event.</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600"></span>
                  <span><strong>Effective Dates:</strong> Coverage typically begins on the first day of the month following enrollment, but this can vary based on when the application is submitted.</span>
                </li>
              </ul>
            </div>
          </div>
        )}
        
        {/* Footer CTA */}
        <div className="mt-16 mb-16 rounded-lg bg-primary-50 p-8 text-center shadow-sm border border-primary-100">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Need Health Insurance Guidance?</h3>
          <p className="text-lg text-gray-600 mb-6">
            United Insurance Professionals is here to help you navigate health insurance options.
            Contact our expert team today for personalized assistance.
          </p>
          <a 
            href="/contact" 
            className="inline-flex items-center rounded-md bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Contact Us
          </a>
        </div>
      </div>
    </main>
  );
}