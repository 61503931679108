import { useState } from 'react';
import { Shield, Heart, PiggyBank } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import MedicareComparisonTable from '../../components/insurance/shared/MedicareComparisonTable';

const plans = [
  {
    name: 'Medicare Advantage',
    description: 'All-in-one Medicare coverage',
    icon: Shield,
    features: [
      'Hospital coverage (Part A)',
      'Medical coverage (Part B)',
      'Prescription drugs (Part D)',
      'Additional benefits'
    ],
    benefits: [
      'Low or $0 monthly premiums',
      'Extra benefits included',
      'One card simplicity',
      'Maximum out-of-pocket protection'
    ]
  },
  {
    name: 'Medicare Supplements',
    description: 'Fill the gaps in Original Medicare',
    icon: Heart,
    features: [
      'Standardized benefits',
      'Guaranteed renewable',
      'No network restrictions',
      'Nationwide coverage'
    ],
    benefits: [
      'Predictable costs',
      'Choose any doctor',
      'No referrals needed',
      'Travel coverage included'
    ]
  },
  {
    name: 'Prescription Drug Plans',
    description: 'Medicare Part D coverage',
    icon: PiggyBank,
    features: [
      'Comprehensive drug coverage',
      'Pharmacy network access',
      'Mail order options',
      'Coverage gap protection'
    ],
    benefits: [
      'Affordable drug costs',
      'Convenient pharmacy access',
      'Save with mail order',
      'Coverage in the gap'
    ]
  }
];

export default function MedicarePage() {
  const [activeTab, setActiveTab] = useState('plans');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Enhanced Page Header with Large Icon */}
        <div className="relative py-16 lg:py-24">
          {/* Background with subtle gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-70"></div>
          
          <div className="relative">
            <div className="mx-auto max-w-5xl">
              <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                {/* Left side with large icon */}
                <div className="lg:w-1/3 flex justify-center">
                  <div className="bg-white p-6 rounded-xl shadow-lg border border-primary-100">
                    <img 
                      src="/assets/images/insurance/medicare/medicare-icon.png" 
                      alt="Medicare Solutions"
                      className="h-32 w-32 lg:h-40 lg:w-40"
                    />
                  </div>
                </div>
                
                {/* Right side with text content */}
                <div className="lg:w-2/3 text-left">
                  <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5 mb-4">
                    <span className="text-sm font-medium text-primary-700">Senior Healthcare</span>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Medicare Solutions
                  </h1>
                  <p className="mt-6 text-lg text-gray-600">
                    United Insurance Professionals provides expert guidance and comprehensive Medicare coverage options 
                    to help seniors make informed healthcare decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Medicare Navigation">
          <button
            onClick={() => setActiveTab('plans')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'plans' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Medicare Plans
          </button>
          <button
            onClick={() => setActiveTab('comparison')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'comparison' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Medicare Comparison
          </button>
          <button
            onClick={() => setActiveTab('enrollment')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'enrollment' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Enrollment Periods
          </button>
        </nav>

        {/* Dynamic Content Based on Active Tab */}
        {activeTab === 'plans' && (
          <div className="mb-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {plans.map((plan) => (
              <PlanCard key={plan.name} plan={plan} />
            ))}
          </div>
        )}

        {activeTab === 'comparison' && (
          <div className="mb-16">
            <div className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Medicare Options Comparison</h2>
              <p className="text-gray-600 mb-8">
                United Insurance Professionals offers this comprehensive comparison to help you understand the differences
                between Medicare options and find what best suits your healthcare needs.
              </p>
            </div>
            
            {/* Medicare Comparison Diagram */}
            <div className="mb-8 bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="flex flex-col md:flex-row items-center gap-6">
                  <div className="md:w-1/3 flex justify-center">
                    <img 
                      src="/assets/images/insurance/medicare/medicare-diagram.png" 
                      alt="Medicare options comparison"
                      className="w-full max-w-[240px] h-auto"
                    />
                  </div>
                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold text-gray-900 mb-3">Understanding Medicare Coverage Options</h3>
                    <p className="text-gray-600 mb-4">
                      This diagram illustrates the relationship between different Medicare coverage options,
                      helping you guide your clients to the right combination of plans for their needs.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="bg-blue-50 p-3 rounded-lg">
                        <h4 className="font-medium text-blue-800">Original Medicare</h4>
                        <p className="text-sm text-gray-600">Parts A & B cover hospital and medical services</p>
                      </div>
                      <div className="bg-blue-50 p-3 rounded-lg">
                        <h4 className="font-medium text-blue-800">Medicare Advantage</h4>
                        <p className="text-sm text-gray-600">Part C combines coverage in one comprehensive plan</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <MedicareComparisonTable />
          </div>
        )}

        {activeTab === 'enrollment' && (
          <div className="mb-16">
            <div className="bg-white rounded-lg shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Medicare Enrollment Periods</h2>
              
              <div className="space-y-8">
                <div className="border-l-4 border-blue-600 pl-4">
                  <h3 className="font-semibold text-lg text-gray-900">Initial Enrollment Period (IEP)</h3>
                  <p className="mt-2 text-gray-600">7-month period beginning 3 months before the month you turn 65, including your birth month, and ending 3 months after.</p>
                </div>

                <div className="border-l-4 border-green-600 pl-4">
                  <h3 className="font-semibold text-lg text-gray-900">Annual Enrollment Period (AEP)</h3>
                  <p className="mt-2 text-gray-600">October 15 - December 7 each year. Changes take effect January 1 of the following year.</p>
                </div>

                <div className="border-l-4 border-purple-600 pl-4">
                  <h3 className="font-semibold text-lg text-gray-900">Medicare Advantage Open Enrollment Period</h3>
                  <p className="mt-2 text-gray-600">January 1 - March 31 each year for Medicare Advantage plan enrollees.</p>
                </div>

                <div className="border-l-4 border-yellow-600 pl-4">
                  <h3 className="font-semibold text-lg text-gray-900">Special Enrollment Periods (SEP)</h3>
                  <p className="mt-2 text-gray-600">Available in certain situations, such as moving, losing employer coverage, or qualifying for Medicaid.</p>
                </div>

                <div className="border-l-4 border-red-600 pl-4">
                  <h3 className="font-semibold text-lg text-gray-900">Medigap Open Enrollment</h3>
                  <p className="mt-2 text-gray-600">6-month period starting the month you're 65 or older and enrolled in Medicare Part B.</p>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Footer Section */}
        <div className="mt-16 mb-16 rounded-lg bg-primary-50 p-8 text-center shadow-sm border border-primary-100">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Need Medicare Guidance?</h3>
          <p className="text-lg text-gray-600 mb-6">
            United Insurance Professionals is here to help you navigate your Medicare options. 
            Contact our expert team today for personalized assistance.
          </p>
          <a 
            href="/contact" 
            className="inline-flex items-center rounded-md bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Contact Us
          </a>
        </div>
      </div>
    </main>
  );
}