import { Check, Minus } from 'lucide-react';

const products = [
  {
    name: 'Term Life',
    description: 'Affordable coverage for a specific period',
    features: {
      'Level Premiums': true,
      'Cash Value Growth': false,
      'Lifetime Coverage': false,
      'Premium Flexibility': false,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': false,
      'Convertibility': true,
    },
    bestFor: [
      'Temporary coverage needs',
      'Maximum coverage at lowest cost',
      'Young families',
      'Mortgage protection',
    ],
  },
  {
    name: 'Whole Life',
    description: 'Permanent protection with guaranteed benefits',
    features: {
      'Level Premiums': true,
      'Cash Value Growth': true,
      'Lifetime Coverage': true,
      'Premium Flexibility': false,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': true,
      'Convertibility': false,
    },
    bestFor: [
      'Lifetime protection',
      'Estate planning',
      'Business succession',
      'Cash value accumulation',
    ],
  },
  {
    name: 'Universal Life',
    description: 'Flexible permanent life insurance',
    features: {
      'Level Premiums': false,
      'Cash Value Growth': true,
      'Lifetime Coverage': true,
      'Premium Flexibility': true,
      'Death Benefit': true,
      'Living Benefits Available': true,
      'Policy Loans': true,
      'Convertibility': false,
    },
    bestFor: [
      'Flexible premium payments',
      'Adjustable coverage amounts',
      'Investment options',
      'Long-term savings goals',
    ],
  },
];

export default function ProductComparison() {
  return (
    <section className="py-16">
      <div className="mx-auto max-w-7xl">
        <div className="text-center">
          <div className="flex items-center justify-center mb-4">
            <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
              <span className="text-sm font-medium text-primary-700">Product Comparison</span>
            </div>
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Compare Life Insurance Products
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Find the right coverage type for your clients' needs
          </p>
        </div>

        {/* Life Insurance Diagram */}
        <div className="mt-12 mb-12 bg-white p-8 rounded-xl shadow-md border border-gray-100">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div className="md:w-1/3 flex justify-center">
              <img 
                src="/assets/images/insurance/life/life-diagram.png" 
                alt="Life insurance product options"
                className="w-full max-w-[280px] h-auto shadow-md rounded-lg"
              />
            </div>
            <div className="md:w-2/3">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Life Insurance Product Hierarchy</h3>
              <p className="text-gray-600 mb-4">
                This diagram illustrates the relationship between different types of life insurance products,
                from term life's affordable temporary coverage to more comprehensive permanent options.
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div className="bg-primary-50 p-4 rounded-lg border border-primary-100">
                  <h4 className="font-medium text-primary-800">Term Life</h4>
                  <p className="text-sm text-gray-600">Affordable coverage for specific periods</p>
                </div>
                <div className="bg-primary-50 p-4 rounded-lg border border-primary-100">
                  <h4 className="font-medium text-primary-800">Whole Life</h4>
                  <p className="text-sm text-gray-600">Lifetime coverage with cash value</p>
                </div>
                <div className="bg-primary-50 p-4 rounded-lg border border-primary-100">
                  <h4 className="font-medium text-primary-800">Universal Life</h4>
                  <p className="text-sm text-gray-600">Flexible permanent coverage</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-200">
          <div className="min-w-full divide-y divide-gray-200">
            {/* Product Names - Header */}
            <div className="grid grid-cols-4 divide-x divide-gray-200 bg-primary-50">
              <div className="px-6 py-5">
                <span className="text-sm font-medium text-gray-900">Features</span>
              </div>
              {products.map((product) => (
                <div key={product.name} className="px-6 py-5 text-center">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {product.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">{product.description}</p>
                </div>
              ))}
            </div>

            {/* Feature Comparisons */}
            {Object.keys(products[0].features).map((feature, idx) => (
              <div
                key={feature}
                className={`grid grid-cols-4 divide-x divide-gray-200 ${idx % 2 === 0 ? 'bg-gray-50/50' : 'bg-white'}`}
              >
                <div className="px-6 py-3.5">
                  <span className="text-sm font-medium text-gray-900">
                    {feature}
                  </span>
                </div>
                {products.map((product) => (
                  <div
                    key={`${product.name}-${feature}`}
                    className="flex items-center justify-center px-6 py-3.5"
                  >
                    {product.features[feature as keyof typeof product.features] ? (
                      <Check className="h-5 w-5 text-primary-600" />
                    ) : (
                      <Minus className="h-5 w-5 text-gray-300" />
                    )}
                  </div>
                ))}
              </div>
            ))}

            {/* Best For Section */}
            <div className="grid grid-cols-4 divide-x divide-gray-200 bg-primary-50/60">
              <div className="px-6 py-5">
                <span className="text-sm font-medium text-gray-900">Best For</span>
              </div>
              {products.map((product) => (
                <div key={`${product.name}-best-for`} className="px-6 py-5">
                  <ul className="space-y-2">
                    {product.bestFor.map((item) => (
                      <li
                        key={item}
                        className="flex items-start text-sm text-gray-600"
                      >
                        <span className="mr-2 mt-1.5 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}