import { Scale, FileText, AlertCircle } from 'lucide-react';

const sections = [
  {
    title: 'Terms of Use',
    icon: FileText,
    content: `By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.`,
    items: [
      'Website access and usage rules',
      'Account creation and maintenance',
      'Service availability and modifications',
      'User responsibilities and conduct'
    ]
  },
  {
    title: 'Legal Compliance',
    icon: Scale,
    content: `Users must comply with all applicable laws and regulations when using our services.`,
    items: [
      'Insurance licensing requirements',
      'State and federal regulations',
      'Data protection compliance',
      'Marketing and solicitation rules'
    ]
  },
  {
    title: 'Disclaimers',
    icon: AlertCircle,
    content: `Important disclaimers regarding the use of our services and website:`,
    items: [
      'No warranty of service',
      'Limitation of liability',
      'Third-party content disclaimer',
      'Investment and insurance risks'
    ]
  }
];

export default function TermsOfServicePage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Terms of Service
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Last Updated: {new Date().toLocaleDateString()}
          </p>
          <p className="mt-4 text-gray-600">
            Please read these Terms of Service carefully before using our website
            or services. These terms govern your use of our website and services.
          </p>
        </div>

        {/* Content Sections */}
        <div className="mx-auto mt-16 max-w-3xl space-y-16">
          {sections.map((section) => (
            <div key={section.title} className="relative">
              <div className="flex items-center gap-4">
                <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                  <section.icon className="h-6 w-6" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {section.title}
                </h2>
              </div>
              
              <div className="mt-4 space-y-6">
                <p className="text-gray-600">{section.content}</p>
                
                <ul className="space-y-3">
                  {section.items.map((item) => (
                    <li key={item} className="flex items-center text-gray-600">
                      <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* Contact Information */}
          <div className="rounded-2xl bg-blue-50 p-8">
            <h2 className="text-xl font-semibold text-gray-900">
              Questions About Our Terms?
            </h2>
            <p className="mt-2 text-gray-600">
              If you have any questions about these Terms of Service, please contact
              our Legal Team at legal@unitedinspros.com
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}