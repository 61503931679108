import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export default function Logo({ className = '', size = 'md' }: LogoProps) {
  return (
    <Link 
      to="/" 
      className={cn('flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 rounded', className)}
      aria-label="United Insurance Professionals - Home"
    >
      <div className="flex items-center">
        <img
          src="/assets/uip-logo-bg.png"
          alt="United Insurance Professionals"
          className={cn(
            'w-auto object-contain',
            size === 'sm' && 'h-12',
            size === 'md' && 'h-14',
            size === 'lg' && 'h-16'
          )}
          width={size === 'sm' ? 120 : size === 'md' ? 140 : 160}
          height={size === 'sm' ? 48 : size === 'md' ? 56 : 64}
          loading="eager"
        />
      </div>
    </Link>
  );
}
