import { useState } from 'react';
import IndividualPlans from '../../components/insurance/life/IndividualPlans';
import FamilyCoverage from '../../components/insurance/life/FamilyCoverage';
import LivingBenefits from '../../components/insurance/life/LivingBenefits';
import ProductComparison from '../../components/insurance/life/ProductComparison';

export default function LifeInsurancePage() {
  const [activeTab, setActiveTab] = useState('plans');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Enhanced Page Header with Large Icon */}
        <div className="relative py-16 lg:py-24">
          {/* Background with enhanced subtle gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-primary-50 via-primary-50/80 to-white opacity-80"></div>
          
          <div className="relative">
            <div className="mx-auto max-w-5xl">
              <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                {/* Left side with large icon */}
                <div className="lg:w-1/3 flex justify-center">
                  <div className="bg-white p-6 rounded-xl shadow-lg border border-primary-200 hover:shadow-xl transition-shadow">
                    <img 
                      src="/assets/images/insurance/life/life-icon.png" 
                      alt="Life Insurance"
                      className="h-32 w-32 lg:h-40 lg:w-40"
                    />
                  </div>
                </div>
                
                {/* Right side with text content */}
                <div className="lg:w-2/3 text-left">
                  <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5 mb-4 border border-primary-200/70 shadow-sm">
                    <span className="text-sm font-medium text-primary-700">Insurance Solutions</span>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Life Insurance Solutions
                  </h1>
                  <p className="mt-6 text-lg text-gray-600">
                    United Insurance Professionals provides comprehensive life insurance solutions designed 
                    to protect what matters most. Explore our range of products and find the perfect coverage for your clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Life Insurance Navigation">
          <button
            onClick={() => setActiveTab('plans')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'plans' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Plan Options
          </button>
          <button
            onClick={() => setActiveTab('comparison')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'comparison' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Product Comparison
          </button>
          <button
            onClick={() => setActiveTab('benefits')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'benefits' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Living Benefits
          </button>
        </nav>

        {/* Content Sections */}
        {activeTab === 'plans' && (
          <div className="mb-16">
            <div className="mb-8 max-w-3xl mx-auto text-center">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Life Insurance Plan Options</h2>
              <p className="text-gray-600 mb-8">
                United Insurance Professionals offers a variety of life insurance solutions to meet different needs, 
                from individual coverage to family protection plans.              
              </p>
            </div>
            
            <div className="mb-16">
              <div className="flex items-center justify-center mb-8">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Individual Coverage</span>
                </div>
              </div>
              <IndividualPlans />
            </div>
            
            <div>
              <div className="flex items-center justify-center mb-8">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Family Protection</span>
                </div>
              </div>
              <FamilyCoverage />
            </div>
          </div>
        )}

        {activeTab === 'comparison' && <ProductComparison />}
        
        {activeTab === 'benefits' && <LivingBenefits />}
        
        {/* Footer CTA */}
        <div className="mt-16 mb-16 rounded-lg bg-gradient-to-br from-primary-50 via-primary-50/80 to-white p-8 text-center shadow-md border border-primary-200/70">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Need Life Insurance Guidance?</h3>
          <p className="text-lg text-gray-600 mb-6">
            United Insurance Professionals is here to help you navigate life insurance options.
            Contact our expert team today for personalized assistance.
          </p>
          <a 
            href="/contact" 
            className="inline-flex items-center rounded-md bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 border border-primary-500/30"
          >
            Contact Us
          </a>
        </div>
      </div>
    </main>
  );
}