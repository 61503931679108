import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../lib/utils';
import type { NavItem } from '../../constants/navigation';

interface NavDropdownProps {
  item: NavItem;
  isActive?: boolean;
  }

const TRANSITION_DURATION = 200; // ms

export default function NavDropdown({ item, isActive = false }: NavDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleToggle = () => {
    setIsTransitioning(true);
    setIsOpen(!isOpen);
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = window.setTimeout(() => {
      setIsTransitioning(false);
    }, TRANSITION_DURATION);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <button
        className={cn(`
          group inline-flex items-center gap-x-2 text-sm font-medium leading-6
          py-2 px-3 border-b-2 transition-all duration-200
          outline-none focus-visible:ring-2 focus-visible:ring-primary-500/50 focus-visible:ring-offset-1
          hover:text-primary-700 hover:border-b-primary-400
        `,
          isOpen ? 'text-primary-800 border-b-primary-500' : 'text-gray-700 border-b-transparent',
          isActive && !isOpen && 'text-primary-800 border-b-primary-500'
        )}
        onClick={handleToggle}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleToggle();
          }
        }}
        aria-expanded={isOpen}
        aria-controls={`${item.name}-dropdown`}
        aria-haspopup="true"
      >
        <span>{item.name}</span>
        <ChevronDown
          className={cn(
            'h-4 w-4 transition-transform duration-200',
            isOpen ? 'rotate-180 text-primary-700' : 'text-gray-400 group-hover:text-primary-600'
          )}
          aria-hidden="true"
        />
      </button>

      <div
        id={`${item.name}-dropdown`}
        className={cn(
          'absolute left-1/2 z-10 mt-2 w-screen max-w-sm -translate-x-1/2 transform',
          'overflow-hidden rounded-lg bg-white shadow-xl border border-gray-200/50',
          'transition-all duration-200 ease-in-out',
          isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2 pointer-events-none',
          isTransitioning && 'will-change-transform will-change-opacity'
        )}
        aria-labelledby={`${item.name}-button`}
      >
        {/* Modern glass card effect */}
        <div className="relative p-4 sm:p-5">
          <div className="absolute inset-0 bg-gradient-to-b from-primary-50 to-white" aria-hidden="true" />
          <ul className="relative z-10 space-y-1.5 list-none p-0 m-0">
            {item.items?.map((subItem) => (
              <li key={subItem.name} className="m-0 p-0">
                <Link
                to={subItem.href}
                className={cn(`
                  group relative flex items-center gap-x-5 rounded-lg p-3 sm:p-4
                  transition-all duration-200
                  hover:bg-primary-50/80 hover:shadow-sm
                  focus-visible:outline-none focus-visible:ring-2
                  focus-visible:ring-primary-500/50 focus-visible:ring-offset-1
                `
                )}
                onClick={() => setIsOpen(false)}
                tabIndex={isOpen ? 0 : -1}
              >
                <div className="flex-auto">
                  <h3 className="text-sm font-medium leading-6 text-gray-900 transition-colors group-hover:text-primary-700">
                    {subItem.name}
                  </h3>
                  {subItem.description && (
                    <p className="mt-1 text-xs sm:text-sm leading-5 text-gray-600 group-hover:text-gray-700">
                      {subItem.description}
                    </p>
                  )}
                </div>
                <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 transition-all duration-200 group-hover:translate-x-1 group-hover:text-primary-700">
                  →
                </span>
              </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}