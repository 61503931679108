import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './ui/Button';
import Logo from './ui/Logo';
import MainNav from './navigation/MainNav';
import MobileNav from './navigation/MobileNav';

export default function Navigation() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed inset-x-0 top-0 z-50 transition-all duration-300 ${
        scrolled
          ? 'bg-white shadow-sm border-b border-gray-200'
          : 'bg-white'
      }`}
      role="banner"
    >
      <div className="container mx-auto">
        <div className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:py-3 lg:px-8">
          <div className="flex lg:flex-1 items-center">
            <a 
              href="#main-navigation" 
              className="sr-only focus:not-sr-only focus:absolute focus:top-4 focus:left-4 px-3 py-2 text-primary-700 bg-white focus:ring-2 focus:ring-primary-500 rounded shadow-sm"
            >
              Skip to main navigation
            </a>
            <Logo className="p-1" size="md" />
          </div>
          
          <MobileNav />
          
          <div className="hidden lg:flex lg:items-center" id="main-navigation">
            <MainNav />
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:ml-8">
            <Link 
              to="/contact"
              aria-label="Get Started with United Insurance Professionals"
              className="focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 rounded"
            >
              <Button
                size="sm"
                className="shadow-sm transition-all duration-300 hover:shadow-md px-5 bg-primary-600 hover:bg-primary-500 text-white border border-primary-500/20"
              >
                Get Started
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}