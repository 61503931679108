import { Shield, Briefcase, Zap, Award, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function WhyPartnerSection() {
  // Benefits data
  const benefits = [
    {
      icon: <Briefcase className="h-5 w-5" />,
      title: 'Build Your Brand',
      description: 'Own your book of business and develop your unique identity'
    },
    {
      icon: <Zap className="h-5 w-5" />,
      title: 'Cutting-Edge Tools',
      description: 'Access advanced platforms to streamline your operations'
    },
    {
      icon: <Award className="h-5 w-5" />,
      title: 'Leading Products',
      description: 'Offer top carriers and competitive insurance solutions'
    }
  ];

  return (
    <div className="lg:col-span-5 animate-fade-in-up mt-8 lg:mt-0" style={{ animationDelay: '0.5s', animationFillMode: 'both' }}>
      {/* Glass card */}
      <div className="bg-white/15 backdrop-blur-md rounded-lg border border-white/30 shadow-xl overflow-hidden">
        {/* Card header */}
        <div className="p-4 sm:p-6 pb-3 sm:pb-4 flex items-center gap-3 border-b border-white/10">
          <div className="bg-primary-600 text-white p-2 rounded-lg shadow-md border border-primary-500/50">
            <Shield className="h-5 w-5" />
          </div>
          <h2 className="text-white text-lg sm:text-xl font-bold">Why Partner With Us</h2>
        </div>
        
        {/* Card content */}
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-5">
          {benefits.map((benefit, index) => (
            <div 
              key={index} 
              className="flex items-start gap-3 sm:gap-4 text-white group hover:bg-white/5 p-3 -mx-3 rounded-lg transition-all"
            >
              <div className="bg-white/20 text-white p-2 sm:p-2.5 rounded-lg shadow-md flex-shrink-0 group-hover:bg-primary-500 transition-colors border border-white/30">
                {benefit.icon}
              </div>
              <div>
                <h3 className="font-semibold text-white text-base sm:text-lg">{benefit.title}</h3>
                <p className="text-white/80 mt-1 text-xs sm:text-sm">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
        
        {/* Card footer */}
        <div className="p-4 sm:p-6 pt-3 border-t border-white/10">
          <Link 
            to="/about/agent-resources"
            className="group inline-flex items-center justify-center w-full px-4 py-2.5 sm:py-3 bg-white text-primary-700 hover:bg-white/90 rounded shadow-sm transition-all"
          >
            <span className="font-medium text-sm sm:text-base">Learn More About Our Benefits</span>
            <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
          </Link>
        </div>
      </div>
    </div>
  );
}