import { Users, Heart, Shield } from 'lucide-react';
import PlanCard from '../shared/PlanCard';

const familyPlans = [
  {
    name: 'Family Term Life',
    description: 'Affordable protection for the whole family',
    icon: Users,
    features: [
      'Coverage for multiple family members',
      'Children rider options',
      'Spouse coverage available',
      'Flexible term lengths'
    ],
    benefits: [
      'Cost-effective family protection',
      'Easy application process',
      'Coverage up to age 26 for children',
      'Convertibility options'
    ]
  },
  {
    name: 'Multi-Life Whole Life',
    description: 'Permanent coverage for your entire family',
    icon: Heart,
    features: [
      'Lifetime coverage guarantee',
      'Cash value accumulation',
      'Family premium discounts',
      'Optional riders for enhanced protection'
    ],
    benefits: [
      'Build generational wealth',
      'Tax-advantaged growth',
      'Family premium savings',
      'Estate planning benefits'
    ]
  },
  {
    name: 'Family Protection Bundle',
    description: 'Comprehensive family insurance package',
    icon: Shield,
    features: [
      'Combined term and permanent coverage',
      'Critical illness protection',
      'Education funding options',
      'Long-term care riders'
    ],
    benefits: [
      'Complete family protection',
      'Flexible coverage options',
      'Premium payment flexibility',
      'Living benefits included'
    ]
  }
];

export default function FamilyCoverage() {
  return (
    <div className="space-y-16">
      {/* Introduction */}
      <div className="rounded-2xl bg-gradient-to-br from-primary-50 to-white p-8 border border-primary-100 shadow-sm">
        <h2 className="text-2xl font-bold text-gray-900">
          Protect Your Entire Family
        </h2>
        <p className="mt-2 text-gray-600">
          Comprehensive coverage solutions designed to protect every member of your family
          with flexible options and affordable rates.
        </p>
      </div>

      {/* Family Plans Grid */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {familyPlans.map((plan) => (
          <PlanCard key={plan.name} plan={plan} />
        ))}
      </div>
    </div>
  );
}