import { Building2, Clock, Mail, MapPin, Phone } from 'lucide-react';
import { useForm } from 'react-hook-form';
import Button from '../components/ui/Button';

type FormData = {
  fullName: string;
  email: string;
  phone: string;
  licenseStatus: 'Currently Licensed' | 'Previously Licensed' | 'Not Licensed';
  experience: '0-2 years' | '3-5 years' | '5+ years';
  interests: string[];
  message: string;
};

const contactInfo = [
  {
    icon: Phone,
    label: 'Phone',
    value: '(740) 497-0933',
    href: 'tel:+17404970933',
  },
  {
    icon: Mail,
    label: 'Email',
    value: 'admin@unitedinspros.com',
    href: 'mailto:admin@unitedinspros.com',
  },
  {
    icon: Building2,
    label: 'Office',
    value: 'United Insurance Professionals',
  },
  {
    icon: MapPin,
    label: 'Address',
    value: '2626 Cole Ave Suite 300, Dallas, TX 75204',
    href: 'https://maps.google.com/?q=2626+Cole+Ave+Suite+300,+Dallas,+TX+75204',
  },
  {
    icon: Clock,
    label: 'Hours',
    value: 'Monday-Friday: 9:00 AM - 5:00 PM EST',
  },
];

export default function ContactPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      // This would normally submit to the endpoint specified in the constants file
      // '/api/inquiries/new-agent' with a POST method
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('Agent inquiry submitted:', data);
      reset();
      alert('Thank you for your interest in joining United Insurance Professionals! Our recruiting team will contact you shortly.');
    } catch (error) {
      console.error('Error submitting inquiry:', error);
      alert('There was an error submitting your inquiry. Please try again or contact us directly.');
    }
  };

  return (
    <main className="relative min-h-screen overflow-hidden bg-gradient-to-b from-gray-50 to-white pt-16">
      <div className="absolute inset-0 bg-grid-gray-900/[0.02] -z-10" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <div className="inline-flex items-center gap-2 rounded-full bg-blue-50 px-4 py-2 text-sm font-medium text-blue-700">
            <Mail className="h-4 w-4" />
            Let's Connect
          </div>
          <h1 className="mt-8 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Connect with United Insurance Professionals
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Take the first step toward partnership. We're here to help and would love to hear from you.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-4xl grid-cols-1 gap-x-12 gap-y-16 lg:grid-cols-2">
          <div className="relative space-y-12">
            <div className="space-y-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Contact Information
              </h2>
              <p className="text-gray-600">
                Reach out to us directly or fill out the form, and our team will get back to you promptly.
              </p>
            </div>
            
            <div className="space-y-6 rounded-lg bg-blue-50 p-6">
              <h3 className="text-xl font-semibold text-gray-900">
                Existing Agent Support
              </h3>
              <p className="text-gray-600">
                Access your agent portal for direct support and resources.
              </p>
              <a 
                href="/agent-portal" 
                className="inline-flex items-center gap-2 rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
              >
                Agent Portal Login
              </a>
            </div>

            <dl className="space-y-8">
              {contactInfo.map((item) => (
                <div
                  key={item.label}
                  className="group relative flex gap-6 rounded-lg bg-white p-4 transition-all hover:bg-blue-50"
                >
                  <dt className="flex-none">
                    <span className="sr-only">{item.label}</span>
                    <div className="rounded-lg bg-blue-50 p-2 text-blue-600 transition-colors group-hover:bg-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </dt>
                  <dd className="flex-auto">
                    <p className="font-medium text-gray-900">{item.label}</p>
                    {item.href ? (
                      <a
                        href={item.href}
                        className="mt-1 block text-gray-600 transition-colors hover:text-blue-600"
                        target={item.href.startsWith('http') ? '_blank' : undefined}
                        rel={item.href.startsWith('http') ? 'noopener noreferrer' : undefined}
                      >
                        {item.value}
                      </a>
                    ) : (
                      <p className="mt-1 text-gray-600">{item.value}</p>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="relative overflow-hidden rounded-2xl bg-white p-8 shadow-xl ring-1 ring-gray-900/5 lg:p-10"
          >
            <div className="absolute inset-0 -z-10">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-white opacity-50" />
            </div>

            <div className="mb-6 space-y-2">
              <h2 className="text-2xl font-bold text-gray-900">New Agent Inquiry</h2>
              <p className="text-gray-600">Fill out this form to join our team of insurance professionals</p>
            </div>

            <div className="space-y-8">
              <div className="space-y-6">
                <div className="relative">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    {...register('fullName', { required: 'Full name is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.fullName
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="John Doe"
                  />
                  {errors.fullName && (
                    <p className="mt-2 text-sm text-red-600">{errors.fullName.message}</p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.email
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="john@example.com"
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    {...register('phone', { required: 'Phone number is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.phone
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                    placeholder="(123) 456-7890"
                  />
                  {errors.phone && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.phone.message}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="licenseStatus"
                    className="block text-sm font-medium text-gray-700"
                  >
                    License Status
                  </label>
                  <select
                    id="licenseStatus"
                    {...register('licenseStatus', { required: 'License status is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.licenseStatus
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                  >
                    <option value="">Select license status</option>
                    <option value="Currently Licensed">Currently Licensed</option>
                    <option value="Previously Licensed">Previously Licensed</option>
                    <option value="Not Licensed">Not Licensed</option>
                  </select>
                  {errors.licenseStatus && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.licenseStatus.message}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <label
                    htmlFor="experience"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Insurance Experience
                  </label>
                  <select
                    id="experience"
                    {...register('experience', { required: 'Experience level is required' })}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow focus:ring-2 focus:ring-inset focus:ring-blue-600 ${
                      errors.experience
                        ? 'ring-red-300 focus:ring-red-500'
                        : 'ring-gray-300'
                    }`}
                  >
                    <option value="">Select experience level</option>
                    <option value="0-2 years">0-2 years</option>
                    <option value="3-5 years">3-5 years</option>
                    <option value="5+ years">5+ years</option>
                  </select>
                  {errors.experience && (
                    <p className="mt-2 text-sm text-red-600">
                      {errors.experience.message}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <fieldset>
                    <legend className="block text-sm font-medium text-gray-700 mb-2">
                      Areas of Interest
                    </legend>
                    <div className="space-y-2">
                      {['Life Insurance', 'Medicare', 'Health Insurance'].map((option) => (
                        <div key={option} className="flex items-center">
                          <input
                            id={option.replace(/\s+/g, '-').toLowerCase()}
                            type="checkbox"
                            value={option}
                            {...register('interests', { required: 'Please select at least one area of interest' })}
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          />
                          <label
                            htmlFor={option.replace(/\s+/g, '-').toLowerCase()}
                            className="ml-3 block text-sm text-gray-700"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                    {errors.interests && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.interests.message}
                      </p>
                    )}
                  </fieldset>
                </div>

                <div className="relative">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Additional Information
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    {...register('message')}
                    className={`mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset transition-shadow placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ring-gray-300`}
                    placeholder="Tell us about your insurance background and goals..."
                  />
                </div>
              </div>

              <Button
                type="submit"
                disabled={isSubmitting}
                className="w-full justify-center gap-2 transition-transform hover:-translate-y-0.5"
                size="lg"
              >
                {isSubmitting ? 'Submitting...' : 'Submit Inquiry'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}