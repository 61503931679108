import { Link } from 'react-router-dom';
import Logo from './ui/Logo';

const navigation = {
  solutions: [
    { name: 'Life Insurance', href: '/insurance/life' },
    { name: 'Health Insurance', href: '/insurance/health' },
    { name: 'Medicare Solutions', href: '/insurance/medicare' },
    { name: 'Annuity Solutions', href: '/insurance/annuities' },
  ],
  company: [
    { name: 'Our Story', href: '/about/story' },
    { name: 'Leadership Team', href: '/about/leadership' },
    { name: 'Company Culture', href: '/about/culture' },
    { name: 'News & Updates', href: '/about/news' },
  ],
  resources: [
    { name: 'Training & Development', href: '/resources/training' },
    { name: 'Document Center', href: '/resources/documents' },
    { name: 'Marketing Resources', href: '/resources/marketing' },
    { name: 'Commission Structure', href: '/resources/commissions' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
    { name: 'Legal Disclosures', href: '/legal' },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-gradient-to-b from-gray-900 via-gray-900 to-primary-950" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-20 lg:px-8 lg:pt-24">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-6">
            <div className="flex flex-col items-start">
              <div className="bg-white/25 p-3 rounded-lg shadow-md border border-white/30">
                <Logo size="md" />
              </div>
            </div>
            <p className="text-sm leading-6 text-gray-300">
              Empowering insurance distribution with enterprise technology solutions
              and professional services.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Solutions
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Resources
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-xs leading-5 text-gray-300">
            &copy; {new Date().getFullYear()} United Insurance Professionals, Inc. All rights
            reserved.
          </p>
          <p className="text-xs leading-5 text-gray-400 mt-4 sm:mt-0">
            <Link to="/privacy" className="hover:text-white transition-colors">Privacy Policy</Link> | <Link to="/terms" className="hover:text-white transition-colors">Terms of Service</Link>
          </p>
        </div>
      </div>
    </footer>
  );
}