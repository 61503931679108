import { ArrowRight, Shield } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import WhyPartnerSection from './WhyPartnerSection';

export default function Hero() {
  return (
    <div className="relative overflow-hidden bg-gray-900 min-h-[90vh]">
      {/* Enhanced full-width background image with better visibility */}
      <div className="absolute inset-0">
        <img
          src="/assets/images/hero/Dallas-Skyline.png"
          alt=""
          aria-hidden="true"
          className="h-full w-full object-cover opacity-90"
        />
        {/* Enhanced gradient overlay for better skyline visibility with improved contrast */}
        <div className="absolute inset-0 bg-gradient-to-r from-primary-900/50 via-primary-800/40 to-primary-700/40" />
        
        {/* Subtle geometric pattern overlay */}
        <div className="absolute inset-0 opacity-10 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMmg1NnY1NkgyVjJ6TTAgMGg2MHY2MEgwVjB6IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS1vcGFjaXR5PSIwLjA1Ii8+Cjwvc3ZnPg==')]" />
      </div>
      
      {/* Main content container with improved spacing */}
      <div className="relative mx-auto max-w-7xl px-6 pt-28 pb-16 sm:pt-36 md:pt-40 lg:pt-44 sm:px-8 lg:px-10">
        <div className="grid grid-cols-1 gap-12 md:gap-14 lg:grid-cols-12 lg:gap-16 items-center">
          <div className="flex flex-col justify-center lg:col-span-7">
            {/* Modern badge with new design - pill shape */}
            <div className="inline-flex self-start items-center gap-2 rounded-full bg-white/20 px-4 py-2 text-sm font-medium text-white shadow-md animate-fade-in-up backdrop-blur-sm border border-white/15">
              <div className="flex items-center gap-1.5">
                <Shield className="h-4 w-4 text-primary-300" />
                <span>Innovation in Insurance Since 2017</span>
              </div>
              
              {/* New feature badge */}
              <div className="ml-2 px-2 py-0.5 bg-primary-500 text-white text-xs font-semibold rounded-full">
                NEW
              </div>
            </div>
            
            {/* Enhanced typography with larger sizing */}
            <h1 className="mt-8 text-5xl sm:text-6xl md:text-7xl font-bold tracking-tight text-white drop-shadow-md">
              <span className="block animate-fade-in-up leading-tight" style={{ animationDelay: '0.1s', animationFillMode: 'both' }}>Empowering Insurance</span>
              <span className="block text-primary-100 animate-fade-in-up leading-tight" style={{ animationDelay: '0.3s', animationFillMode: 'both' }}>Entrepreneurs</span>
            </h1>
            
            {/* Cleaner description with enhanced readability */}
            <p className="mt-8 text-lg leading-relaxed text-white animate-fade-in-up" style={{ animationDelay: '0.5s', animationFillMode: 'both' }}>
              United Insurance Professionals is the ultimate platform where entrepreneurs 
              work together to create a synergistic approach to insurance. We focus on individual 
              agent success, providing the tools and support needed to build your own brand and legacy.
            </p>
            
            {/* Modernized CTA Buttons with refined styling */}
            <div className="mt-10 flex flex-col sm:flex-row gap-4 animate-fade-in-up" style={{ animationDelay: '0.7s', animationFillMode: 'both' }}>
              <Link to="/contact" className="w-full sm:w-auto group">
                <Button 
                  size="lg" 
                  className="w-full px-8 flex items-center justify-center gap-2 bg-primary-500 text-white 
                  hover:bg-primary-400 shadow-lg border border-primary-400/20 transition-all duration-300"
                >
                  Join Our Network
                  <ArrowRight className="h-5 w-5 ml-1 transition-transform group-hover:translate-x-1" />
                </Button>
              </Link>
              <Link to="/about" className="w-full sm:w-auto">
                <Button 
                  size="lg" 
                  variant="outline" 
                  className="w-full px-8 flex items-center justify-center border border-white/30 text-white 
                  hover:bg-white/10 shadow-md transition-all duration-300 backdrop-blur-sm"
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
          
          {/* Modern tabbed "Why Partner With Us" section */}
          <WhyPartnerSection />
        </div>
      </div>
    </div>
  );
}