import { ReactNode, forwardRef } from 'react';
import { cn } from '../../../lib/utils';

// Define card variants
export type CardVariant = 'default' | 'outline' | 'filled';
export type CardSize = 'sm' | 'md' | 'lg';

export interface CardProps {
  children: ReactNode;
  className?: string;
  variant?: CardVariant;
  size?: CardSize;
  hasBorder?: boolean;
  accentColor?: string;
  hasShadow?: boolean;
  isHoverable?: boolean;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ 
    children, 
    className = '',
    variant = 'default',
    size = 'md',
    hasBorder = true,
    accentColor,
    hasShadow = true,
    isHoverable = false,
    ...props 
  }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'relative overflow-hidden',
          {
            // Variant styles
            'bg-white': variant === 'default',
            'bg-transparent': variant === 'outline',
            'bg-primary-50': variant === 'filled',
            
            // Border styles
            'border': hasBorder,
            'border-gray-100': hasBorder && variant === 'default',
            'border-gray-200': hasBorder && variant === 'outline',
            'border-primary-100': hasBorder && variant === 'filled',
            
            // Shadow styles
            'shadow-sm': hasShadow && variant === 'default',
            'shadow': hasShadow && variant === 'outline',
            'shadow-md': hasShadow && variant === 'filled',
            
            // Size styles
            'p-4 rounded': size === 'sm',
            'p-6 rounded-md': size === 'md',
            'p-8 rounded-lg': size === 'lg',
            
            // Hover effects
            'transition-all duration-200': isHoverable,
            'hover:shadow-md': isHoverable && variant === 'default',
            'hover:border-primary-200 hover:shadow': isHoverable && variant === 'outline',
            'hover:shadow-lg': isHoverable && variant === 'filled',
          },
          className
        )}
        {...props}
      >
        {accentColor && (
          <div 
            className="absolute top-0 left-0 right-0 h-1"
            style={{ background: accentColor }}
          />
        )}
        {children}
      </div>
    );
  }
);

Card.displayName = 'Card';

export default Card;