import { useState } from 'react';
import { PiggyBank, TrendingUp, Lock, Calendar } from 'lucide-react';
import PlanCard from '../../components/insurance/shared/PlanCard';
import { ANNUITY_SOLUTIONS } from '../../constants/content/insurance/annuity';

const plans = [
  {
    name: 'Fixed Annuities',
    description: 'Guaranteed interest rates and principal protection',
    icon: Lock,
    features: [
      'Guaranteed interest rates',
      'Principal protection',
      'Tax-deferred growth',
      'Predictable income options'
    ],
    benefits: [
      'Stable returns',
      'No market risk',
      'Guaranteed income',
      'Estate planning tool'
    ]
  },
  {
    name: 'Fixed Indexed Annuities',
    description: 'Market-linked potential with downside protection',
    icon: TrendingUp,
    features: [
      'Index-linked returns',
      'Principal protection',
      'Multiple index options',
      'Flexible crediting methods'
    ],
    benefits: [
      'Growth potential',
      'Downside protection',
      'Tax-deferred growth',
      'Optional income riders'
    ]
  },
  {
    name: 'Multi-Year Guarantee Annuities',
    description: 'Locked-in rates for extended periods',
    icon: PiggyBank,
    features: [
      'Guaranteed rate period',
      'Multiple term options',
      'Competitive rates',
      'Systematic growth'
    ],
    benefits: [
      'Rate certainty',
      'Predictable growth',
      'Tax advantages',
      'Legacy planning'
    ]
  }
];

// Income strategies content
const incomeStrategies = [
  {
    name: "Immediate Income",
    description: "Converting a lump sum to guaranteed income payments that start right away",
    features: [
      "Immediate income stream",
      "Guaranteed payments for life or set period",
      "Protection against outliving savings",
      "Options for beneficiary protection"
    ]
  },
  {
    name: "Deferred Income",
    description: "Building a future income stream with growth potential before payments begin",
    features: [
      "Tax-deferred accumulation phase",
      "Potentially higher future payments",
      "Flexible income start date",
      "Optional inflation protection"
    ]
  },
  {
    name: "Laddering Strategy",
    description: "Purchasing multiple annuities over time to diversify and increase flexibility",
    features: [
      "Diversification across products and time",
      "Ability to capture changing interest rates",
      "Increased liquidity compared to single purchase",
      "Phased income planning approach"
    ]
  }
];

export default function AnnuityPage() {
  const [activeTab, setActiveTab] = useState('products');

  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Enhanced Page Header with Large Icon */}
        <div className="relative py-16 lg:py-24">
          {/* Background with subtle gradient */}
          <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-70"></div>
          
          <div className="relative">
            <div className="mx-auto max-w-5xl">
              <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                {/* Left side with large icon */}
                <div className="lg:w-1/3 flex justify-center">
                  <div className="bg-white p-6 rounded-xl shadow-lg border border-primary-100">
                    <img 
                      src="/assets/images/insurance/annuity/annuitie-icon.png" 
                      alt="Annuity Solutions"
                      className="h-32 w-32 lg:h-40 lg:w-40"
                    />
                  </div>
                </div>
                
                {/* Right side with text content */}
                <div className="lg:w-2/3 text-left">
                  <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5 mb-4">
                    <span className="text-sm font-medium text-primary-700">Retirement Solutions</span>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    {ANNUITY_SOLUTIONS.pageTitle}
                  </h1>
                  <p className="mt-6 text-lg text-gray-600">
                    United Insurance Professionals provides {ANNUITY_SOLUTIONS.heroSection.subheadline.toLowerCase()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-8 flex justify-center space-x-4" aria-label="Annuity Navigation">
          <button
            onClick={() => setActiveTab('products')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'products' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Annuity Products
          </button>
          <button
            onClick={() => setActiveTab('comparison')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'comparison' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Product Comparison
          </button>
          <button
            onClick={() => setActiveTab('income')}
            className={`rounded-lg px-5 py-2.5 text-sm font-medium transition-colors
              ${activeTab === 'income' 
                ? 'bg-primary-600 text-white shadow-md' 
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary-600'}`}
          >
            Income Strategies
          </button>
        </nav>

        {/* Products Tab */}
        {activeTab === 'products' && (
          <div className="mb-16">
            <div className="mb-8 max-w-3xl mx-auto text-center">
              <div className="flex items-center justify-center mb-4">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Product Offerings</span>
                </div>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Annuity Products</h2>
              <p className="text-gray-600 mb-8">
                United Insurance Professionals offers diverse annuity solutions to help your clients secure 
                retirement income and build financial security.
              </p>
            </div>
            
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {plans.map((plan) => (
                <PlanCard key={plan.name} plan={plan} />
              ))}
            </div>
            
            {/* Important Information */}
            <div className="mt-12 rounded-2xl bg-primary-50 p-8 shadow-sm border border-primary-100">
              <h3 className="text-lg font-semibold text-gray-900">
                {ANNUITY_SOLUTIONS.importantInformation.title}
              </h3>
              <ul className="mt-4 space-y-3">
                {ANNUITY_SOLUTIONS.importantInformation.points.map((point, idx) => (
                  <li key={idx} className="flex items-start text-sm text-gray-600">
                    <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600" />
                    <span><strong>{point.title}</strong>: {point.description}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Comparison Tab */}
        {activeTab === 'comparison' && (
          <div className="mb-16">
            <div className="mb-8 max-w-3xl mx-auto text-center">
              <div className="flex items-center justify-center mb-4">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Product Comparison</span>
                </div>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Annuity Product Comparison</h2>
              <p className="text-gray-600 mb-8">
                Compare key features across different annuity types to identify the right solution for your clients' needs.
              </p>
            </div>
            
            {/* Annuity Diagram */}
            <div className="mb-12 bg-white p-8 rounded-xl shadow-md border border-gray-100">
              <div className="flex flex-col md:flex-row items-center gap-8">
                <div className="md:w-1/3 flex justify-center">
                  <img 
                    src="/assets/images/insurance/annuity/annuitie-diagram.png" 
                    alt="Types of annuities chart"
                    className="w-full max-w-[280px] h-auto shadow-md rounded-lg"
                  />
                </div>
                <div className="md:w-2/3">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">Understanding Annuity Options</h3>
                  <p className="text-gray-600 mb-4">
                    This diagram illustrates the different types of annuities available, helping you
                    guide clients to the right solution based on their retirement income needs and risk tolerance.
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="bg-primary-50 p-4 rounded-lg border border-primary-100">
                      <h4 className="font-medium text-primary-800">Fixed Annuities</h4>
                      <p className="text-sm text-gray-600">Guaranteed rates with principal protection</p>
                    </div>
                    <div className="bg-primary-50 p-4 rounded-lg border border-primary-100">
                      <h4 className="font-medium text-primary-800">Indexed Annuities</h4>
                      <p className="text-sm text-gray-600">Market-linked growth with downside protection</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="overflow-hidden rounded-xl bg-white shadow ring-1 ring-gray-200">
              <div className="p-6 sm:p-8">
                <h3 className="text-xl font-semibold text-gray-900">
                  {ANNUITY_SOLUTIONS.comparisonTable.title}
                </h3>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {ANNUITY_SOLUTIONS.comparisonTable.headers.map((header, idx) => (
                        <th 
                          key={idx}
                          scope="col" 
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {ANNUITY_SOLUTIONS.comparisonTable.rows.map((row, idx) => (
                      <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                          {row.feature}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {row.fixed}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {row.indexed}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                          {row.myga}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            
            <div className="mt-12 bg-white rounded-lg p-8 shadow-md border border-gray-100">
              <div className="flex items-center mb-4">
                <div className="rounded-lg bg-primary-100 p-2.5 w-12 h-12 flex items-center justify-center text-primary-600 mr-4">
                  <Calendar className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">
                  Suitability Considerations
                </h3>
              </div>
              <p className="mt-2 text-gray-600 mb-6">
                When recommending annuity products, it's crucial to consider your client's:
              </p>
              <ul className="mt-4 space-y-3 text-gray-600">
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600"></span>
                  <span>Age and retirement timeline</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600"></span>
                  <span>Income needs and timing</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600"></span>
                  <span>Risk tolerance and financial objectives</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600"></span>
                  <span>Liquidity requirements and emergency fund access</span>
                </li>
                <li className="flex items-start">
                  <span className="mt-1 mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600"></span>
                  <span>Overall financial and retirement portfolio composition</span>
                </li>
              </ul>
            </div>
          </div>
        )}
        
        {/* Income Strategies Tab */}
        {activeTab === 'income' && (
          <div className="mb-16">
            <div className="mb-8 max-w-3xl mx-auto text-center">
              <div className="flex items-center justify-center mb-4">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Income Planning</span>
                </div>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl mb-4">Annuity Income Strategies</h2>
              <p className="text-gray-600 mb-8">
                United Insurance Professionals helps you develop effective income strategies for your clients using annuity products.
              </p>
            </div>
            
            <div className="grid gap-8 md:grid-cols-3">
              {incomeStrategies.map((strategy, index) => (
                <div key={index} className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-md ring-1 ring-gray-100 transition-all hover:shadow-xl">
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
                  
                  <div className="relative">
                    {/* Header */}
                    <div className="flex items-center gap-4 mb-6">
                      <div className="rounded-lg bg-primary-100 p-2.5 w-12 h-12 flex items-center justify-center text-primary-600">
                        <span className="font-bold">{index + 1}</span>
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold text-gray-900">{strategy.name}</h3>
                        <p className="text-sm text-gray-600">{strategy.description}</p>
                      </div>
                    </div>
                    
                    <div className="mt-8">
                      <h4 className="text-sm font-medium text-gray-900 mb-3">Key Features</h4>
                      <ul className="mt-4 space-y-3">
                        {strategy.features.map((feature, idx) => (
                          <li key={idx} className="flex items-start text-sm text-gray-600">
                            <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="mt-16">
              <div className="flex items-center justify-center mb-8">
                <div className="inline-flex items-center rounded-full bg-primary-100 px-4 py-1.5">
                  <span className="text-sm font-medium text-primary-700">Planning Strategies</span>
                </div>
              </div>
              
              <div className="grid gap-8 md:grid-cols-2">
                <div className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-md ring-1 ring-gray-100 hover:shadow-lg">
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
                  
                  <div className="relative">
                    <div className="flex items-center gap-4 mb-6">
                      <div className="rounded-lg bg-primary-100 p-2.5 w-12 h-12 flex items-center justify-center text-primary-600">
                        <TrendingUp className="h-6 w-6" />
                      </div>
                      <h3 className="text-xl font-semibold text-gray-900">Retirement Income Planning</h3>
                    </div>
                    
                    <p className="text-gray-600 mb-6">
                      Help clients create sustainable retirement income streams using annuities as part of a comprehensive strategy.
                    </p>
                    
                    <ul className="space-y-3">
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Blend guaranteed income with growth potential</span>
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Cover essential expenses with guaranteed income</span>
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Address inflation and longevity risks</span>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div className="group relative overflow-hidden rounded-2xl bg-white p-8 shadow-md ring-1 ring-gray-100 hover:shadow-lg">
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-50/50 via-white to-white opacity-0 transition-opacity group-hover:opacity-100" />
                  
                  <div className="relative">
                    <div className="flex items-center gap-4 mb-6">
                      <div className="rounded-lg bg-primary-100 p-2.5 w-12 h-12 flex items-center justify-center text-primary-600">
                        <PiggyBank className="h-6 w-6" />
                      </div>
                      <h3 className="text-xl font-semibold text-gray-900">Tax-Efficient Distribution</h3>
                    </div>
                    
                    <p className="text-gray-600 mb-6">
                      Optimize tax outcomes through strategic annuity selection and withdrawal sequencing.
                    </p>
                    
                    <ul className="space-y-3">
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Leverage tax-deferred growth advantages</span>
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Coordinate with other retirement accounts</span>
                      </li>
                      <li className="flex items-start text-sm text-gray-600">
                        <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-primary-600 mt-1.5" />
                        <span>Balance taxable and tax-advantaged income</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Footer CTA */}
        <div className="mt-16 mb-16 rounded-lg bg-primary-50 p-8 text-center shadow-sm border border-primary-100">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Need Annuity Planning Assistance?</h3>
          <p className="text-lg text-gray-600 mb-6">
            United Insurance Professionals provides expert guidance on annuity solutions.
            Contact our team today for personalized assistance.
          </p>
          <a 
            href="/contact" 
            className="inline-flex items-center rounded-md bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Contact Us
          </a>
        </div>
      </div>
    </main>
  );
}