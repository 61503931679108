import { Scale, FileCheck, AlertTriangle } from 'lucide-react';

const sections = [
  {
    title: 'Insurance Disclosures',
    icon: Scale,
    content: `Important information about our insurance products and services:`,
    items: [
      'Insurance products and services are offered through United Insurance Professionals, Inc.',
      'Products vary by state and carrier',
      'Not all products are available in all states',
      'Coverage is subject to policy terms and conditions'
    ]
  },
  {
    title: 'Regulatory Information',
    icon: FileCheck,
    content: `Compliance with state and federal regulations:`,
    items: [
      'Licensed in all states where we conduct business',
      'Adherence to state insurance regulations',
      'Compliance with federal privacy laws',
      'Regular regulatory reporting and audits'
    ]
  },
  {
    title: 'Risk Disclosures',
    icon: AlertTriangle,
    content: `Important risk considerations for insurance products:`,
    items: [
      'Insurance guarantees are subject to claims-paying ability',
      'Product values may fluctuate',
      'Past performance does not guarantee future results',
      'Consider your needs and objectives carefully'
    ]
  }
];

export default function LegalDisclosuresPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Legal Disclosures
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Last Updated: {new Date().toLocaleDateString()}
          </p>
          <p className="mt-4 text-gray-600">
            Important legal information and disclosures about our insurance products,
            services, and regulatory compliance.
          </p>
        </div>

        {/* Content Sections */}
        <div className="mx-auto mt-16 max-w-3xl space-y-16">
          {sections.map((section) => (
            <div key={section.title} className="relative">
              <div className="flex items-center gap-4">
                <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                  <section.icon className="h-6 w-6" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {section.title}
                </h2>
              </div>
              
              <div className="mt-4 space-y-6">
                <p className="text-gray-600">{section.content}</p>
                
                <ul className="space-y-3">
                  {section.items.map((item) => (
                    <li key={item} className="flex items-center text-gray-600">
                      <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* State Specific Notices */}
          <div className="rounded-2xl bg-blue-50 p-8">
            <h2 className="text-xl font-semibold text-gray-900">
              State-Specific Notices
            </h2>
            <p className="mt-2 text-gray-600">
              Insurance regulations vary by state. Please contact us at compliance@unitedinspros.com for specific
              information about products and services available in your state.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}