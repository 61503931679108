import { Shield, Lock, Eye } from 'lucide-react';

const sections = [
  {
    title: 'Information Collection',
    icon: Shield,
    content: `We collect information that you provide directly to us, including personal information such as your name, email address, phone number, and any other information you choose to provide. We also automatically collect certain information about your device when you use our services.`,
    items: [
      'Contact information (name, email, phone)',
      'Professional information',
      'Device and usage information',
      'Communication preferences'
    ]
  },
  {
    title: 'Information Usage',
    icon: Eye,
    content: `We use the information we collect to provide, maintain, and improve our services, to develop new services, and to protect UIP and our users. This includes using the information to:`,
    items: [
      'Provide and deliver the services you request',
      'Send you technical notices and support messages',
      'Communicate with you about products and services',
      'Monitor and analyze trends and usage'
    ]
  },
  {
    title: 'Information Security',
    icon: Lock,
    content: `We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. These measures include:`,
    items: [
      'Encryption of data in transit and at rest',
      'Regular security assessments',
      'Access controls and authentication',
      'Security monitoring and logging'
    ]
  }
];

export default function PrivacyPolicyPage() {
  return (
    <main className="min-h-screen bg-gray-50 pt-16">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mx-auto max-w-2xl lg:text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Privacy Policy
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Last Updated: {new Date().toLocaleDateString()}
          </p>
          <p className="mt-4 text-gray-600">
            Your privacy is important to us. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you visit our website
            or use our services.
          </p>
        </div>

        {/* Content Sections */}
        <div className="mx-auto mt-16 max-w-3xl space-y-16">
          {sections.map((section) => (
            <div key={section.title} className="relative">
              <div className="flex items-center gap-4">
                <div className="rounded-lg bg-blue-50 p-2 text-blue-600">
                  <section.icon className="h-6 w-6" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {section.title}
                </h2>
              </div>
              
              <div className="mt-4 space-y-6">
                <p className="text-gray-600">{section.content}</p>
                
                <ul className="space-y-3">
                  {section.items.map((item) => (
                    <li key={item} className="flex items-center text-gray-600">
                      <span className="mr-3 h-1.5 w-1.5 flex-none rounded-full bg-blue-600" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          {/* Additional Information */}
          <div className="rounded-2xl bg-blue-50 p-8">
            <h2 className="text-xl font-semibold text-gray-900">
              Questions About Our Privacy Policy?
            </h2>
            <p className="mt-2 text-gray-600">
              If you have any questions or concerns about our Privacy Policy, please
              contact our Privacy Team at privacy@unitedinspros.com
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}