import { Link, useLocation } from 'react-router-dom';
import { MAIN_NAV } from '../../constants/navigation';
import NavDropdown from './NavDropdown';
import { cn } from '../../lib/utils';

export default function MainNav() {
  const location = useLocation();
  const currentPath = location.pathname;
  
  // Check if a path is active (exact match or starts with for nested routes)
  const isActive = (path: string) => {
    if (path === '/') {
      return currentPath === path;
    }
    return currentPath.startsWith(path);
  };
  
  return (
    <nav 
      className="mx-auto flex max-w-7xl items-center gap-x-6 px-6 lg:px-8" 
      aria-label="Main navigation"
      id="desktop-navigation"
    >
      <ul className="flex items-center gap-x-6 list-none p-0 m-0">
      {MAIN_NAV.map((item) => (
        <li key={item.name} className="relative">
          {item.items ? (
            <NavDropdown 
              item={item} 
              isActive={isActive(item.href)}
            />
          ) : (
            <Link
              to={item.href}
              className={cn(`
                py-2 px-3 text-sm font-medium leading-6 whitespace-nowrap border-b-2
                transition-all duration-300 
                outline-none focus-visible:ring-2 focus-visible:ring-primary-500/50 focus-visible:ring-offset-1
                hover:text-primary-600 hover:border-b-primary-400
              `,
                isActive(item.href) 
                  ? 'text-primary-700 border-b-primary-500' 
                  : 'text-gray-700 border-b-transparent'
              )}
              aria-current={isActive(item.href) ? 'page' : undefined}
            >
              {item.name}
            </Link>
          )}
        </li>
      ))}
      </ul>
    </nav>
  );
}